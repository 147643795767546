var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "result-err" },
    [
      _c("img", { attrs: { src: require("@/assets/svg/500.svg"), alt: "" } }),
      _c("div", [_vm._v(" 对不起，服务器错误。 ")]),
      _c(
        "a-button",
        {
          staticStyle: { "margin-top": "30px" },
          attrs: { type: "primary" },
          on: { click: _vm.toHome },
        },
        [_vm._v(" 返回首页 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }